import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthState } from 'app/src/Auth';
import { apiClient } from 'app/src/services/httpClients/app';
import { GET_ALL_PROPOSALS_KEY } from 'app/src/services/proposal';
import { ConfirmModal, DatePicker, useToast } from 'common';
import React, { useState } from 'react';

interface Props {
  handleClose: () => void;
  isOpen: boolean;
}

export const BulkExpireModal: React.FC<Props> = ({
  handleClose,
  isOpen,
}: Props) => {
  const [expireDate, setExpireDate] = useState<string>('');

  const { auth } = useAuthState();

  const queryClient = useQueryClient();

  const toast = useToast();

  const expireBulkProposals = () =>
    apiClient.bulkExpireProposals({
      date: expireDate,
      orgId: auth.organizationId,
    });

  const { mutate } = useMutation({
    mutationFn: expireBulkProposals,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: [GET_ALL_PROPOSALS_KEY],
      });
      handleClose();
      toast.success('Bulk expired proposals successfully');
      window.location.reload();
    },
  });

  return (
    <ConfirmModal
      cancelText="Clear"
      confirmText="Expire"
      header="Bulk Expire"
      isDisabled={!expireDate}
      isOpen={isOpen}
      onClose={handleClose}
      onConfirm={mutate}
    >
      <div className="mx-20">
        <DatePicker
          onChange={(value) => setExpireDate(value)}
          value={expireDate}
        />
      </div>
    </ConfirmModal>
  );
};
