import React, { useState } from 'react';
import { Button } from 'common';
import { BulkExpireModal } from './BulkExpireModal';

export const BulkExpireButton: React.FC = () => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <div
      style={{
        marginRight: '10px',
      }}
    >
      <BulkExpireModal handleClose={() => setOpen(false)} isOpen={open} />
      <Button label="Bulk Expire" onClick={() => setOpen(true)} />
    </div>
  );
};
